<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filterNames"
      search-label="search"
      @filterOption="onFiltersChange"
    >
      <template v-slot:warranty="{ item }">
        <div>{{ item.warranty_limit }} - {{ item.warranty_type }}</div>
      </template>
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="isView"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-if="isEdit"
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="isDelete"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <v-card>
          <v-select
            v-model="customFilters.category_id"
            filled
            dense
            :loading="isCategory"
            :items="(options.tenant_id || options.fleetId) && categoryList"
            item-value="id"
            item-text="name"
            :label="$t('category')"
            @change="fetchData"
          />
        </v-card>
      </template>
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div>
          <create-part
            v-if="isCreate"
            :filter-options="options"
          />

          <edit-part
            v-if="(isEdit || isView) && editModal"
            :is-view="isView"
            :is-visible-dialog="editModal"
            :toggle-edit="toggleEditModal"
            :item="selectedRow"
          />
          <confirmation-modal
            v-if="isDelete"
            :is-visible="deleteModal"
            :toggle="toggleDeleteModal"
            :row="selectedRow"
          />
        </div>
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreatePart: () => import("./dialogs/CreatePart.vue"),
    EditPart: () => import("./dialogs/EditPart.vue"),
    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      // module_name: "csmsChargeStations", //vuex module name which define in Store Index
      permissions: {
        view: "parts.view",
        create: "parts.create",
        edit: "parts.edit",
        delete: "parts.delete",
      },
      //
      customFilters: {},
      //
      filters: {},
      subFilters: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "parts/getLoading",
      meta: "parts/getMeta",
      list: "parts/getList",
      //
      isCategory: "category/getLoading",
      categoryList: "category/getList",
    }),
    _headers() {
      const headers = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
        },
        {
          text: this.$t("name"),
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("specification"),
          align: "left",
          sortable: false,
          value: "specification",
        },
        {
          text: this.$t("manufacturer"),
          align: "left",
          sortable: false,
          value: "manufacturer",
        },
        {
          text: this.$t("description"),
          align: "left",
          sortable: false,
          value: "description",
        },
        {
          text: this.$t("category_name"),
          align: "left",
          sortable: false,
          value: "category_name",
        },
        {
          text: this.$t("warranty"),
          align: "left",
          sortable: false,
          value: "warranty",
        },
        {
          text: this.$t("tenant_name"),
          align: "left",
          sortable: false,
          value: "tenant_name",
          showAdmin: true,
        },

        ...(this.isView || this.isEdit || this.isDelete
          ? [
              {
                text: this.$t("actions"),
                align: "left",
                sortable: false,
                value: "actions",
              },
            ]
          : []),
      ];

      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },

    filterNames() {
      return [];
    },
  },
  watch: {
    "options.tenant_id": {
      handler: function (v) {
        this.getCategory(v);
      },
    },
    "options.fleetId": {
      handler: function (v) {
        this.getCategory(v);
      },
    },
    options() {
      this.fetchData();
    },
  },
  beforeDestroy() {
    const params = {
      name: "parts",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
    this.$store.commit("parts/SET_LIST", []);
  },
  mounted() {},
  methods: {
    async fetchData() {
      const params = { ...this.options, ...this.customFilters };
      await this.$store.dispatch("parts/list", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },
    //
    getCategory(v) {
      if (v) {
        const params = {
          product: this.options.product,
          tenant_id: this.options.tenant_id,
          fleetId: this.options.fleetId,
        };
        this.$store.dispatch("category/list", params);
      } else {
        this.$store.commit("category/SET_LIST", []);
      }
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("parts/destroy", row);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
